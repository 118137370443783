import React, { FC, useState } from 'react';
// import { FILE_TYPE } from '../../../lib/helpers/consts';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import { uploadFile } from '../../../api/apis';

type SecretContentFieldProps = {
	field: FormField;
	formValues: any;
	// fileType: FILE_TYPE;
	language?: string;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

enum ContentType {
	FILE = 'file',
	LINK = 'link',
}

const SecretContentField: FC<SecretContentFieldProps> = ({
	field,
	formValues,
	// fileType,
	language,
	handleNamedContentFieldChange,
}) => {
	console.log(language);
	const [contentType, setContentType] = useState<ContentType | undefined>(
		formValues[field.name]?.options?.type,
	);
	const [fieldName, setFieldName] = useState<string | undefined>(
		formValues[field.name]?.options?.fieldName,
	);
	const [content, setContent] = useState<string | undefined>(
		formValues[field.name]?.value,
	);

	const handleUpdateContentType = (value: ContentType) => {
		setContentType(value);
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					type: value,
				},
			},
			field.name,
		);
	};
	const handleUpdateFieldName = (value: string) => {
		setFieldName(value);
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					fieldName: value,
				},
			},
			field.name,
		);
	};
	const handleUpdateContent = (value: string) => {
		setContent(value);
		handleNamedContentFieldChange(
			{
				value,
				options: formValues[field.name]?.options,
			},
			field.name,
		);
	};
	const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event?.target?.files?.[0];
		if (file) {
			const body = new FormData();
			body.append('image', file);
			uploadFile(body).then(
				(res) => {
					console.log('RES URL: ', res.url);
					handleUpdateContent(res.url);
				},
				(err) => {
					console.error(err);
				},
			);
		}
	};

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ marginRight: '10px' }}>
					<FormControl sx={{ m: 1, minWidth: 140 }}>
						<InputLabel id="content-type-label">Content type</InputLabel>
						<Select
							labelId="content-type-label"
							label="Content type"
							value={contentType}
							onChange={(event: SelectChangeEvent) =>
								handleUpdateContentType(event.target.value as ContentType)
							}
							size="small"
							variant="outlined"
						>
							{Object.values(ContentType).map((value) => (
								<MenuItem value={value} key={value}>
									{value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				{!!contentType && (
					<div style={{ marginRight: '10px' }}>
						<TextField
							value={fieldName}
							label={`${contentType} name`}
							onChange={(event) => handleUpdateFieldName(event.target.value)}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</div>
				)}
				{contentType === ContentType.FILE && (
					<div>
						<label htmlFor="image">
							<input
								// required={required}
								type="file"
								id="image"
								// name={fileType}
								// accept={acceptTypes}
								onChange={onFileChange}
							/>
						</label>
						{content && (
							<Typography>
								File:{' '}
								<a href={content} download>
									Link
								</a>
							</Typography>
						)}
					</div>
				)}
				{contentType === ContentType.LINK && (
					<div>
						<TextField
							label="Link"
							value={content}
							onChange={(event) => handleUpdateContent(event.target.value)}
							variant="outlined"
							fullWidth
							size="small"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default SecretContentField;

import { ISubscriptionPlan } from './SubscriptionPlan';

export enum SubscriptionStatus {
	CREATED = 'created',
	ACTIVE = 'active',
	SUSPENDED = 'suspended',
	CANCELED = 'canceled',
}

export interface ISubscription {
	id: number;
	providerSubscriptionId: number;
	paymentProvider: string;
	status: SubscriptionStatus;
	startDate: string;
	endDate: null;
	created: string;
	plan: ISubscriptionPlan;
}

import { CONTENT_ENTITIES } from '../../../lib/helpers/consts';
import { AdditionalFormFields } from '../../../lib/interfaces/Form';
import { FIELD_TYPES } from '../formUtils';

export const availableContentFields: AdditionalFormFields = {
	[FIELD_TYPES.RICH_EDITOR]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.RICH_EDITOR,
		availableForEntity: Object.values(CONTENT_ENTITIES).map((value) => value),
	},
	[FIELD_TYPES.TEXT]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.TEXT,
		availableForEntity: Object.values(CONTENT_ENTITIES).map((value) => value),
	},
	[FIELD_TYPES.CODE_TASK_REF]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.CODE_TASK_REF,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.QUIZ]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.QUIZ,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.QUIZ_EDITOR]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.QUIZ_EDITOR,
		availableForEntity: [CONTENT_ENTITIES.QUIZ],
	},
	[FIELD_TYPES.HINT]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.HINT,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.OVERVIEW_BLOCK]: {
		name: 'overview',
		init: '',
		label: 'Overview',
		required: false,
		type: FIELD_TYPES.OVERVIEW_BLOCK,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.CONCLUSION_BLOCK]: {
		name: 'conclusion',
		init: '',
		label: 'Conclusion',
		required: false,
		type: FIELD_TYPES.CONCLUSION_BLOCK,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.VIDEO_REF]: {
		name: 'name',
		init: '',
		label: 'Video',
		required: false,
		type: FIELD_TYPES.VIDEO_REF,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.FILE]: {
		name: 'imageUrl',
		init: '',
		label: 'Image URL',
		required: true,
		type: FIELD_TYPES.FILE,
		availableForEntity: Object.values(CONTENT_ENTITIES).map((value) => value),
	},
	[FIELD_TYPES.VIDEO]: {
		name: 'videoUrl',
		init: '',
		label: 'Video URL',
		required: true,
		type: FIELD_TYPES.VIDEO,
		availableForEntity: [CONTENT_ENTITIES.VIDEO_ITEM],
	},
	[FIELD_TYPES.VIDEO]: {
		name: 'videoUrl',
		init: '',
		label: 'Video URL',
		required: true,
		type: FIELD_TYPES.VIDEO,
		availableForEntity: [CONTENT_ENTITIES.VIDEO_ITEM],
	},
	[FIELD_TYPES.CODE_EDITOR]: {
		name: 'codeExample',
		init: '',
		label: 'Code example',
		required: false,
		type: FIELD_TYPES.CODE_EDITOR,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.CODESANDBOX_EMBED]: {
		name: 'codesandboxEmbed',
		init: '',
		label: 'Codesandbox embed',
		required: false,
		type: FIELD_TYPES.CODESANDBOX_EMBED,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.CODE_BLOCK]: {
		name: 'codeBlock',
		init: '',
		label: 'Code block',
		required: false,
		type: FIELD_TYPES.CODE_BLOCK,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.SAND_PACK]: {
		name: 'sandPack',
		init: '',
		label: 'SandPack',
		required: false,
		type: FIELD_TYPES.SAND_PACK,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.SAND_PACK_WITH_TEXT]: {
		name: 'sandPackWithText',
		init: '',
		label: 'SandPack with text',
		required: false,
		type: FIELD_TYPES.SAND_PACK_WITH_TEXT,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.API_CONTROL_BLOCK]: {
		name: 'apiControlBlock',
		init: '',
		label: 'APIs',
		required: false,
		type: FIELD_TYPES.API_CONTROL_BLOCK,
		availableForEntity: [CONTENT_ENTITIES.API_CONTROL],
	},
	[FIELD_TYPES.API_CONTROL_REF]: {
		name: 'apiControlRef',
		init: '',
		label: 'APIs',
		required: false,
		type: FIELD_TYPES.API_CONTROL_REF,
		availableForEntity: [CONTENT_ENTITIES.TASK],
	},
	[FIELD_TYPES.SECRET_CONTENT]: {
		name: 'name',
		init: '',
		label: 'Name',
		required: false,
		type: FIELD_TYPES.SECRET_CONTENT,
		availableForEntity: Object.values(CONTENT_ENTITIES).map((value) => value),
	},
};

export const fastAccessContentFields: string[] = [
	FIELD_TYPES.RICH_EDITOR,
	FIELD_TYPES.CONCLUSION_BLOCK,
	// FIELD_TYPES.CODE_BLOCK,
	FIELD_TYPES.SAND_PACK_WITH_TEXT,
];

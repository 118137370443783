import * as React from 'react';
import { CircularProgress, TablePagination, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';
import { User } from '../../lib/interfaces/User';
import { getPaginatedUsers } from '../../api/apiRequests/user';
import { SubscriptionStatus } from '../../lib/interfaces/Subscription';
import {
	IPaginationV2,
	TPaginationSettings,
} from '../../lib/interfaces/PaginationV2';

const UsersTable = () => {
	const [pagination, setPagination] = React.useState<
		TPaginationSettings | undefined
	>({ page: 1, limit: 10 });
	const paginationKey = pagination
		? `${pagination.page}-${pagination.limit}`
		: '';
	const { data } = useQuery<IPaginationV2<User>, Error>(
		[['users', paginationKey].join('_')],
		() => getPaginatedUsers({ pagination }),
	);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		page: number,
	) => {
		if (data) {
			setPagination({
				page: page + 1,
				limit: data.meta.itemsPerPage,
			});
		}
	};
	// const users = data?.items.map((user) => ({
	// 	...user,
	// 	name: `${user.firstName} ${user.lastName}`,
	// 	isActive: user.isActive ? 'yes' : 'no',
	// 	roles: user.role.join(', '),
	// }));
	const getSubscription = (user: User) => {
		const activeSubscriptions = user?.subscriptions?.filter(
			(s) => s.status === SubscriptionStatus.ACTIVE,
		);
		const plans = activeSubscriptions?.map((s) => s.plan.name);
		return plans?.join(',');
	};
	const getSubscriptionDate = (user: User) => {
		const activeSubscriptions = user?.subscriptions?.filter(
			(s) => s.status === SubscriptionStatus.ACTIVE,
		);
		return activeSubscriptions?.map((s) => s.created);
	};
	return data && data?.data ? (
		<>
			<Typography variant="h6">Users [{data.meta.totalItems}]</Typography>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>Id</TableCell>
							<TableCell align="left">Name</TableCell>
							<TableCell align="left">Email</TableCell>
							<TableCell align="left">Roles</TableCell>
							<TableCell align="left">Active</TableCell>
							<TableCell align="left">Created</TableCell>
							<TableCell align="left">Subscription</TableCell>
							<TableCell align="left">Subscription Date</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.data.map((user) => (
							<TableRow
								key={user.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{user.id}
								</TableCell>
								<TableCell align="left">{`${user.firstName} ${user.lastName}`}</TableCell>
								<TableCell align="left">{user.email}</TableCell>
								<TableCell align="left">{user.role.join(',')}</TableCell>
								<TableCell align="left">{user.isActive ? 'yes' : 'no'}</TableCell>
								<TableCell align="left">
									<Moment format="DD.MM.YYYY HH:MM">{user.createdAt}</Moment>
								</TableCell>
								<TableCell align="left">{getSubscription(user)}</TableCell>
								<TableCell align="left">
									{getSubscriptionDate(user)?.map((d) => (
										<div key={d}>
											<Moment format="DD.MM.YYYY HH:MM">{d}</Moment>
										</div>
									))}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5]}
					component="div"
					count={data.meta.totalItems}
					rowsPerPage={data.meta.itemsPerPage}
					page={data.meta.currentPage - 1}
					onPageChange={handleChangePage}
				/>
			</TableContainer>
		</>
	) : (
		<CircularProgress />
	);
};

export default UsersTable;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	IconButton,
	Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTranslation } from 'react-i18next';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../lib/interfaces/Form';
import FileUpload from '../FormFields/FileUpload';
import Tags from '../FormFields/Tags';
import { FIELD_TYPES, INPUT_TYPES, RefType } from './formUtils';
import InputTextField from './InputTextField';
import './Form.scss';
import CustomFieldMenu from './CustomFieldMenu';
import CodeEditorField from '../FormFields/CodeEditor/CodeEditorField';
import QuizEditorField from '../FormFields/QuizEditor/QuizEditorField';
import SelectField from '../FormFields/SelectField';
import QuizFieldInTask from '../Quizzes/QuizFieldInTask';
import { FILE_TYPE } from '../../lib/helpers/consts';
import RefSelectorField from '../FormFields/RefSelector/RefSelectorField';
import ContentBlockField from '../FormFields/ContentBlock/ContentBlockField';
import CoursePicker from '../FormFields/CoursePicker/CoursePicker';
import CodeBlock from '../FormFields/CodeBlock/CodeBlock';
import SandPackWrapper from '../FormFields/SandPack/SandPack';
import TextContentWrapperField from '../FormFields/TextContent/TextContentWrapper';
import FastReorderCustomField from './FastReorderCustomField';
import ApiControlField from '../FormFields/ApiControl/ApiControlField';
import SecretContentField from '../FormFields/SecretContent/SecretContentField';

type FormFieldRendererProps = {
	field: FormField;
	fieldIndex: number;
	fieldsLength: number;
	formValues: any;
	validation: any;
	language: string;
	collapseAll: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleTagsChange: (
		value: string | number | undefined | (string | number)[],
		name: string,
	) => void;
	handleFileUploaded: (value: string | undefined, name: string) => void;
	handleRihEditorChange: (data: string, name: string) => void;
	handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	// handleHintChange?: (data: IEntityContentFormValuesField, name: string) => void;
	handleCustomFieldAction?: (data: any) => void;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
	handleSimpleUpdate: (value: any, fieldName: string) => void;
	// handleQuizEditorChange: () => void;
};

const FormFieldRenderer: FC<FormFieldRendererProps> = ({
	field,
	fieldIndex,
	fieldsLength,
	formValues,
	validation,
	language,
	collapseAll,
	handleChange,
	handleTagsChange,
	handleCheckboxChange,
	handleNamedContentFieldChange,
	handleCustomFieldAction,
	handleSimpleUpdate,
}) => {
	const { t } = useTranslation();
	const renderErrorMessage = (fieldName: string) => (
		<div className="Form__field__error">{validation[fieldName]}</div>
	);
	const [collapsed, setCollapsed] = useState<boolean>(collapseAll || false);

	useEffect(() => {
		setCollapsed(collapseAll);
	}, [collapseAll]);

	let fieldRenderer;
	if (field.type === FIELD_TYPES.DIVIDER) {
		fieldRenderer = (
			<Typography variant="h5" component="h5">
				{field.label}
			</Typography>
		);
	}
	if (field.type === FIELD_TYPES.TEXT) {
		fieldRenderer = (
			<InputTextField
				onChange={handleChange}
				variant="outlined"
				required={field.required}
				fullWidth
				label={field.label || t(`field_${FIELD_TYPES.TEXT}`)}
				name={field.name}
				type={field.inputType || INPUT_TYPES.TEXT}
				defaultValue={formValues[field.name]?.value || ''}
				className={validation[field.name] ? 'invalid' : ''}
				fieldInputType={field.inputType}
			/>
		);
	}
	if (field.type === FIELD_TYPES.MULTISELECT) {
		fieldRenderer = (
			<Tags
				{...field}
				defaultValue={formValues[field.name]?.value}
				onItemsChange={(val: (string | number)[] | string | number | undefined) =>
					handleTagsChange(val, field.name)
				}
				className={validation[field.name] ? 'invalid' : ''}
			/>
		);
	}
	if (
		field.type === FIELD_TYPES.RICH_EDITOR ||
		field.type === FIELD_TYPES.OVERVIEW_BLOCK ||
		field.type === FIELD_TYPES.CONCLUSION_BLOCK ||
		field.type === FIELD_TYPES.HINT
	) {
		fieldRenderer = (
			<ContentBlockField
				field={field}
				formValues={formValues}
				validation={validation}
				handleContentBlockChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.FILE) {
		fieldRenderer = (
			<FileUpload
				field={field}
				required={field.required}
				formValues={formValues}
				fileType={FILE_TYPE.IMAGE}
				handleFileChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.VIDEO) {
		fieldRenderer = (
			<FileUpload
				field={field}
				required={field.required}
				formValues={formValues}
				fileType={FILE_TYPE.VIDEO}
				handleFileChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.SELECT) {
		fieldRenderer = (
			<SelectField
				field={field}
				formValues={formValues}
				validation={validation}
				handleChange={handleSimpleUpdate}
			/>
		);
	}
	if (field.type === FIELD_TYPES.CHECKBOX) {
		const checkbox = (
			<Checkbox
				// {...field}
				name={field.name}
				checked={!!formValues[field.name]?.value}
				onChange={handleCheckboxChange}
			/>
		);
		fieldRenderer = (
			<FormGroup>
				<FormControlLabel control={checkbox} label={field.label} />
			</FormGroup>
		);
	}
	if (field.type === FIELD_TYPES.CODE_EDITOR) {
		fieldRenderer = (
			<div>
				<CodeEditorField
					field={field}
					formValues={formValues}
					validation={validation}
					handleCodeEditorChange={handleNamedContentFieldChange}
				/>
			</div>
		);
	}
	// if (field.type === FIELD_TYPES.CODE_TASK_REF) {
	// 	fieldRenderer = (
	// 		<CodeTaskFieldInTask
	// 			field={field}
	// 			formValues={formValues}
	// 			handleNamedContentFieldChange={handleNamedContentFieldChange}
	// 		/>
	// 	);
	// }
	if (field.type === FIELD_TYPES.CODE_TASK_REF) {
		fieldRenderer = (
			<RefSelectorField
				field={field}
				formValues={formValues}
				language={language}
				refType={RefType.CODE_TASK_REF}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.QUIZ) {
		fieldRenderer = (
			<QuizFieldInTask
				field={field}
				formValues={formValues}
				language={language}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.VIDEO_REF) {
		fieldRenderer = (
			<RefSelectorField
				field={field}
				formValues={formValues}
				language={language}
				refType={RefType.VIDEO_REF}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.QUIZ_EDITOR) {
		fieldRenderer = (
			<QuizEditorField
				field={field}
				formValues={formValues}
				handleQuizEditorChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.COURSE_PICKER) {
		fieldRenderer = (
			<CoursePicker
				field={field}
				formValues={formValues}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.CODESANDBOX_EMBED) {
		fieldRenderer = (
			<InputTextField
				onChange={handleChange}
				variant="outlined"
				required={field.required}
				fullWidth
				label={field.label || t(`field_${FIELD_TYPES.CODESANDBOX_EMBED}`)}
				name={field.name}
				type={field.inputType || INPUT_TYPES.TEXT}
				defaultValue={formValues[field.name]?.value || ''}
				className={validation[field.name] ? 'invalid' : ''}
				fieldInputType={field.inputType}
			/>
		);
	}
	if (field.type === FIELD_TYPES.CODE_BLOCK) {
		fieldRenderer = (
			<CodeBlock
				field={field}
				formValues={formValues}
				language={language}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.SAND_PACK) {
		fieldRenderer = (
			<SandPackWrapper
				field={field}
				formValues={formValues}
				language={language}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.SAND_PACK_WITH_TEXT) {
		fieldRenderer = (
			<TextContentWrapperField
				field={field}
				formValues={formValues}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.READ_ONLY) {
		fieldRenderer = (
			<InputTextField
				variant="outlined"
				required={field.required}
				fullWidth
				label={field.label || t(`field_${FIELD_TYPES.TEXT}`)}
				name={field.name}
				type={field.inputType || INPUT_TYPES.TEXT}
				defaultValue={formValues[field.name]?.value.toString() || ''}
				className={validation[field.name] ? 'invalid' : ''}
				fieldInputType={field.inputType}
				disabled
			/>
		);
	}
	// console.log('field.type: ', field, FIELD_TYPES.API_CONTROL_BLOCK);
	if (field.type === FIELD_TYPES.API_CONTROL_BLOCK) {
		fieldRenderer = (
			<ApiControlField
				field={field}
				formValues={formValues}
				language={language}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.API_CONTROL_REF) {
		fieldRenderer = (
			<RefSelectorField
				field={field}
				formValues={formValues}
				language={language}
				refType={RefType.API_CONTROL_REF}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (field.type === FIELD_TYPES.SECRET_CONTENT) {
		fieldRenderer = (
			<SecretContentField
				field={field}
				formValues={formValues}
				language={language}
				handleNamedContentFieldChange={handleNamedContentFieldChange}
			/>
		);
	}
	if (!Object.values(FIELD_TYPES).includes(field.type)) {
		return (
			<div>
				This field type {field.name} {field.type} is not supported.
			</div>
		);
	}
	const subLabel =
		formValues[field.name]?.options?.heading ||
		formValues[field.name]?.options?.label ||
		'';
	const eliipsedSubLabel =
		subLabel && typeof subLabel === 'string' && subLabel.length > 10
			? `${subLabel.substring(0, 10)}...`
			: subLabel;
	return (
		<div key={field.name} className="Form__field">
			<div className="Form__field__divider">
				<div className="Form__field__divider__content">
					<IconButton
						onClick={() => setCollapsed(!collapsed)}
						sx={{
							width: '14px',
							height: '14px',
							color: 'white',
							marginRight: '10px',
						}}
					>
						{!collapsed && <ArrowDropDownIcon />}
						{collapsed && <ArrowRightIcon />}
					</IconButton>{' '}
					<div className="Form__field__divider__number">{fieldIndex + 1}</div>{' '}
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => setCollapsed(!collapsed)}
					>
						{field.label}{' '}
						{!!subLabel && <span style={{ opacity: 0.3 }}>- {eliipsedSubLabel}</span>}
					</span>
				</div>
				<div className="Form__field__divider__fast-reorder">
					{field.custom && handleCustomFieldAction ? (
						<FastReorderCustomField
							handleCustomFieldAction={handleCustomFieldAction}
							field={field}
							fieldIndex={fieldIndex}
							fieldsLength={fieldsLength}
						/>
					) : null}
				</div>
			</div>
			<div
				style={{
					padding: '20px',
					display: collapsed ? 'none' : 'flex',
				}}
			>
				<div style={{ width: '100%' }}>
					{fieldRenderer}
					{validation[field.name] && renderErrorMessage(field.name)}
				</div>
				<div style={{ width: '20px' }}>
					{field.custom && handleCustomFieldAction ? (
						<CustomFieldMenu
							handleCustomFieldAction={handleCustomFieldAction}
							field={field}
							fieldIndex={fieldIndex}
							fieldsLength={fieldsLength}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default FormFieldRenderer;
